<template>
    <header id="header">
        <!--PC header-->
        <div class="header_container open">
            <div class="inner">
                <div class="logo">
                    <a href="main"><img :src="require('@/assets/img/main/tinteccnc_logo.svg')"/></a>
                </div>
                <div class="m_menu">
                    <a href="#"><img :src="require('@/assets/img/main/m_menu.svg')"/></a>
                </div>

                <div class="header_menu_wrap">
                    <ul>
                        <li>
                            <a href="about">ABOUT</a>
                            <div class="sub_menu menu01">
                                <ul>
                                    <li>Profile</li>
                                    <li>M.P.S Business</li>
                                    <li>S.I Business</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a href="service">SERVICE</a>
                            <div class="sub_menu menu02">
                                <ul>
                                    <li>TN-BOX(WEB)</li>
                                    <li>TN-BOX(Mobile)</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a href="partners">PARTNERS</a>
                            <div class="sub_menu menu03">
                                <ul>
                                    <li>Partners Program</li>
                                    <li>Becoming a Partner</li>
                                </ul>
                            </div>
                        </li>
                        <!--<li>
                            <a href="support">SUPPORT</a>
                            <div class="sub_menu menu04">
                                <ul>
                                    <li>Manual Download</li>
                                    <li>Remote Download</li>

                                </ul>
                            </div>
                        </li>-->
                        <li>
                            <a href="contactUs">CONTACT US</a>
                            <div class="sub_menu menu05">
                                <ul>
                                    <li>msg&Info</li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a href="recruit">RECRUIT</a>
                            <div class="sub_menu menu05">
                                <ul>
                                    <li>msg&Info</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--mobile header-->
        <div class="inner m_header">
            <div class="logo">
                <a href="main"><img :src="require('@/assets/img/main/main_logo_svg.svg')"/></a>
            </div>
            <div @click="openNav">
                <a href="#"><img :src="require('@/assets/img/main/m_menu.svg')"/></a>
            </div>


            <div class="m_header_submenu_wrap" v-show="showSubMenu">
                <div class="sub_menu_background" @click="closeNav"></div>
                <div class="side_submenu">
                    <ul>
                        <div class="side_submenu_li_wrap">
                            <li class="font-weight-bold"><a href="main" @click="onCLickNavLink">HOME</a></li>
                            <li class="font-weight-bold"><a href="about" @click="onCLickNavLink">ABOUT</a></li>
                            <!-- <li><a href="#">Profile</a></li>
                             <li><a href="#">MPS Business</a></li>
                             <li><a href="#">SI Business</a></li>-->
                            <li class="font-weight-bold"><a href="service" @click="onCLickNavLink">SERVICE</a></li>
                            <!--<li><a href="#">TN-BOX(Web)</a></li>
                            <li><a href="#">TN-BOX(Mobile)</a></li>-->
                            <li class="font-weight-bold"><a href="partners" @click="onCLickNavLink">PARTNERS</a></li>
                            <!-- <li><a href="#">Partners Programs</a></li>
                             <li><a href="#">Becoming a Partner</a></li>-->
                            <!--<li class="font-weight-bold li_background"><a href="#">SUPPORT</a></li>-->
                            <!-- <li><a href="#">Manual Download</a></li>
                             <li><a href="#">Remote Download</a></li>-->
                            <li class="font-weight-bold"><a href="contactUs" @click="onCLickNavLink">CONTACT US</a></li>
                            <!--  <li><a href="#">msg & Info</a></li>-->
                            <li class="font-weight-bold"><a href="recruit" @click="onCLickNavLink">RECRUIT</a></li>
                        </div>
                    </ul>
                    <div class="m_close_button" @click="closeNav">
                        <img :src="require('@/assets/img/main/m_cloes_white.svg')"/>
                    </div>
                </div>
            </div>
        </div>


    </header>
</template>

<style scoped>
    #header {
        width: 100%;
        margin: 0 auto;
        height: 100px;

    }

    .m_header {
        display: none;
    }

    .header_container {
        position: absolute;
        height: 100px;
        width: 100%;
        left: 0;
        z-index: 1000;
    }

    .open {
        display: block;
        /*background-color: #F9F9F9;*/
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ start*/
    .header_container {
        position: relative;
        /*background: #f9f9f9;*/
        height: 300px;
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ end*/
    .header_container::before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
    }

    .header_container.open::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
        background-color: #fff;
    }


    .header_container .inner {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 100px;
        margin: 0 auto;
    }


    .header_container .logo {
        position: absolute;
        top: 36px;
        z-index: 111;
        width: 166px;
    }

    .header_container .logo img {
        width: 100%;
    }


    .header_menu_wrap {
        position: absolute;
        height: 100px;
        z-index: 30;
        width: 100%;
        left: 0px;
        top: 37px;
    }

    .header_menu_wrap > ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-left: 281px;
    }

    .header_menu_wrap > ul > li {
        width: 170px;
        display: inline-block;
        text-align: right;
        position: relative;
    }

    .header_menu_wrap > ul > li > a:hover {
        color: #0f70b7;
    }

    .sub_menu > ul > li:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .header_menu_wrap > ul > li > a {

        font-size: 1.8rem;
        letter-spacing: -0.54px;
        line-height: 27px;
        font-weight: 700;
        color: #000;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .header_menu_wrap > ul > li > a:hover {
        text-decoration: none;
    }


    .sub_menu {
        position: absolute;
        top: 63px;
        left: 50px;
        z-index: 30;
        padding-bottom: 36px;
        display: none;
    }

    .menu01 {
        left: 109px;
    }

    .menu02 {
        left: 71px;
    }

    .menu03 {
        left: 75px;
    }

    .menu04 {
        left: 86px;
    }

    .menu05 {
        left: 68px;
    }


    .sub_menu > ul {
        /* list-style: none;
         padding: 0;
         max-width: 1200px;
         margin: 0 auto;*/
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
    }

    .sub_menu > ul > li {
        /*width: calc(25% - 36px);*/
        width: 170px;

        padding-top: 26px;
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #333;
        text-align: left;
    }

    /*pc header end*/

    @media screen and (max-width: 1199px) and (min-width: 750px) {
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 150px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */
        .m_header div:nth-child(2) {
            width: 30px;
            height: 30px;
        }

        .m_header div:nth-child(2) img {
            width: 100%;
        }


        /*tablet 서브메뉴 display 유무 코드*/
        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            /*display: none;*/
        }

        /*tablet 서브메뉴 display 유무 코드*/
        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 1.6rem;
            padding-top: 10px;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 50%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 45% !important;
            top: -7px;
        }

        .m_close_button > img {
            width: 24px;
            height: 24px;
        }

        .header_container {
            display: none;
            width: 100%;

        }

        .header_menu_wrap {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 340px;
            top: -7px;
        }

        /*.m_menu > a > img {
            display: block;
            position: absolute;
            z-index: 2;
            right: 0;
            top: 39px;
            width: 30px;
            height: 30px;
        }*/
        .open {
            display: none;
        }

        /*header end*/
    }

    @media (max-width: 749px) {
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 96px;
            height: 25px;
        }

        .logo > a {
            display: inline-block;
        }

        .logo > a > img {
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */
        /* mobile 서브메뉴 display 유무코드*/
        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            transition: width 1s;
        }

        /* mobile 서브메뉴 display 유무코드*/
        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            padding-top: 10px;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 70%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
            overflow-y: auto;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            /*background-color: #EDF2F6;*/
            background-color: white;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 24%;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */
        .sidenav {
            height: 100%;
            width: 0;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: #111;
            overflow-x: hidden;
            transition: 0.5s;
            padding-top: 60px;
        }

        .sidenav a {
            padding: 8px 8px 8px 32px;
            text-decoration: none;
            font-size: 25px;
            color: #818181;
            display: block;
            transition: 0.3s;
        }

        .sidenav a:hover {
            color: #f1f1f1;
        }

        .sidenav .closebtn {
            position: absolute;
            top: 0;
            right: 25px;
            font-size: 36px;
            margin-left: 50px;
        }


    }
</style>

<script>
    export default {
        name: 'CommonHeader',
        data() {
            return {
                showSubMenu: false,
            }
        },
        methods: {
            openNav() {
                this.showSubMenu = true;
            },
            closeNav() {
                this.showSubMenu = false;
            },
            onCLickNavLink() {
                this.showSubMenu = false;
            }
        }
    }
</script>
