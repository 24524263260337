<template>
    <footer>
        <div class="footer">

            <div class="footer_wrap">
                <div>
                    <img :src="require('@/assets/img/main/logo_footer.png')"/>
                </div>

                <div class="footer_flex row">
                    <div class="foottxt">서울특별시 강서구 마곡동 마곡중앙2로 매트로비즈타워 504호 사업자등록번호 808-87-01665
                        <span>© Copyright 2020. All Rights Reserved.</span>
                    </div>

                    <div class="familyarea col-lg-2">
                        <select name="family">
                            <option value="site">FAMILY SITE</option>
                        </select>
                    </div>
                </div>
            </div>


        </div>
    </footer>
</template>

<style scoped>
    .footer {
        width: 100%;
        height: 100px;
        background-color: #ececec;
    }

    .footer_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        font-size: 1.3rem;
        color: #9a9a9a;
        padding: 23px 20px 0px 0px;
    }

    .footer_wrap > div {
        margin-top: -13px;
    }

    .footer_flex {
        line-height: 18px;
        width: 100%;
        padding-left: 180px;
    }

    .footer_flex span {
        display: block;
        margin-top: 4px;
        font-size: 1.3rem;
        color: #9a9a9a;
    }

    .familyarea {
        margin-left: 72px;
    }

    .familyarea select {
        margin-left: 20px;
    }

    select {
        background: transparent;
        border: 1px solid #cccccc !important;
        width: 170px !important;
        height: 30px;
        color: #9a9a9a;
    }

    @media screen and (max-width: 1199px) and (min-width: 750px) {
        /*footer*/
        .footer {
            width: 100%;
            height: 130px;
            background-color: #ececec;
            padding-bottom: 20px;
        }

        .footer .footer_wrap {
            width: 100%;
            padding: 0 40px;
        }

        .footer_wrap > div {
            margin-top: 14px;
        }

        .footer_flex {
            line-height: 18px;
            width: 100%;
            padding-left: 50px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }

        .footer_flex span {
            display: block;
            margin-top: 4px;
            font-size: 1.3rem;
            color: #9a9a9a;
        }

        .familyarea {
            margin-left: 0px;
            margin-top: 10px;
            padding: 0;
        }

        .familyarea select {
            margin-left: 0px;
        }
    }

    @media (max-width: 749px) {
        .footer {
            width: 100%;
            height: 197px;
            padding: 0 20px;
        }

        .footer_wrap {
            flex-direction: column;
            width: 100%;
            padding-top: 0;
        }

        .footer_wrap > div:first-child {
            width: 122px;
        }

        .footer_wrap > div:first-child > img {
            width: 100%;
            padding-top: 15px;
        }

        .footer_wrap > div {
            text-align: center;
            margin: 0 auto;
        }

        .footer_wrap > div > img {
            width: 140px;
        }

        .footer_flex {
            padding-left: 0;
            flex-direction: column-reverse;
        }

        .foottxt {
            width: 100%;
            margin: 0 auto;
            padding-top: 25px;
        }

        .familyarea {
            margin-left: 0;
            padding-top: 10px;
        }
    }
</style>

<script>
    export default {
        name: 'CommonFooter'
    }
</script>
