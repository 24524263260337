<template>
    <!-- <div>-->
    <!--<div>
        <v-btn @click="$i18n.locale='en'">English</v-btn>
        <v-btn @click="$i18n.locale='ko'">한국어</v-btn>
    </div>
    <div>{{$t('message')}}</div>-->

    <div>
        <common-header></common-header>

        <div role="main" class="main">
            <section>
                <div class="main_background"
                     :style="{backgroundImage: 'url(' + require('@/assets/img/main/main_circle_mask02.svg') + ')' }">
                    <!--<img :src="require('@/assets/img/main/main_circle_mask.svg')"/>-->
                    <div class="text_position">
                        <div class="text_wrap">
                            <div>MANAGED PRINTER SERVICE SYSTEM</div>
                            <div>TN-BOX 출시</div>
                            <div class="test">
                                <img :src="require('@/assets/img/main/main_plus.svg')"/>
                            </div>
                            <div>실시간 모니터링 시스템으로 <strong>언제, 어디서나</strong> 프린터의 상태를 파악하여 정확한 정보를 제공받을 수 있습니다.</div>
                            <button><a href="service">자세히 보기</a></button>
                        </div>
                    </div>
                </div>

            </section>

            <section>
                <div class="light_gray_background">
                    <div class="text_solution_wrap">
                        <div>TN-BOX M.P.S Solution</div>
                        <div>프린터 렌탈 사용자는 물론 관리자, 엔지니어까지 모두 만족시키는 기능을 겸비한</div>
                        <div><strong>커스터마이징 프린터 임대 솔루션!</strong></div>
                        <div>
                            <img :src="require('@/assets/img/main/solution_img.png')"
                                 style="width: 100%; box-sizing: border-box;"/>
                        </div>
                        <div>이미 <span>전세계의 회원이 사용</span>하고 있습니다.</div>
                    </div>

                </div>
            </section>


            <section>
                <div class="three_content">
                    <div class="service_wrap">
                        <div>TN-BOX M.P.S 서비스 특징</div>
                        <div><strong>언제, 어디서나</strong>불필요한 단계를 거치지 않고 사용자의 요구를 정확히 파악하여 <strong>실시간 정보</strong>를 제공 받을 수
                            있습니다.
                        </div>
                        <div class="detail_view_button">
                            <p class="stxt mr-4"><a href="service">자세히 보러가기</a></p>
                            <img :src="require('@/assets/img/sub/right_arrow_black.svg')">
                        </div>
                        <ul>
                            <li>
                                <img :src="require('@/assets/img/main/main_tnbox_service_01_2.svg')"/>
                                <div>
                                    <div>Mornitoring</div>
                                    <div class="font_size">Connected IOT와</div>
                                    <div class="font_size">실시간 통합 모니터링</div>
                                </div>
                            </li>
                            <li>
                                <img :src="require('@/assets/img/main/main_tnbox_service_02_2.svg')"/>
                                <div>
                                    <div>A/S Workforce Management</div>
                                    <div class="font_size">A/S 작업자의 실시간 위치 파악,</div>
                                    <div class="font_size">손쉬운 작업 관리</div>
                                </div>
                            </li>
                            <li>
                                <img :src="require('@/assets/img/main/main_tnbox_service_03_2.svg')"/>
                                <div>
                                    <div>Automation</div>
                                    <div class="font_size">설정 기반의 매출, 입출금,</div>
                                    <div class="font_size">세금계산서 자동화 기술</div>
                                </div>
                            </li>
                            <li>
                                <img :src="require('@/assets/img/main/main_tnbox_service_04_2.svg')"/>
                                <div>
                                    <div>Report</div>
                                    <div class="font_size">빅데이터 기반의 보고서</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section>
                <div class="four_content">
                    <div class="video_wrap">
                        <div>TN-BOX</div>
                        <div>TN-BOX 인쇄 관리 프로그램을 통해 고객은 <strong>모든 인쇄 정보를 한곳에</strong>서</div>
                        <div>신속하게 확인함으로써 업무 효율성을 극대화할 수 있습니다.</div>
                        <div class="tnbox_video_wrap">
                            <div class="tn_box_video">
                                <img :src="require('@/assets/img/main/video_bg1.svg')"/>
                                <div class="video_position">
                                    <video class="pc_tnbox_video" :src="require('@/assets/img/main/video/tnbox.mp4')"
                                           width="267px"
                                           muted autoplay controls></video>
                                    <video class="m_tnbox_video" :src="require('@/assets/img/main/video/tnbox.mp4')"
                                           width="115px" height="75px"
                                           muted autoplay controls></video>
                                </div>
                                <!--<img :src="require('@/assets/img/main/video_1.png')"/>-->
                                <div class="video_text_wrap">
                                    <div>TN-BOX 동영상</div>
                                    <div>TN-BOX 주요 지원 화면을 확인해 보세요</div>
                                    <div>사용자 맞춤형 APPLICATION으로 24시간 모니터링합니다.</div>
                                </div>
                            </div>

                            <div class="mps_video">
                                <img :src="require('@/assets/img/main/video_bg2.svg')"/>
                                <div class="video_position2">
                                    <video class="pc_mps_video"
                                           :src="require('@/assets/img/main/video/mps_video.mp4')" width="267px"
                                           muted autoplay controls></video>
                                    <video class="m_mps_video"
                                           :src="require('@/assets/img/main/video/mps_video.mp4')" width="115px"
                                           height="75px"
                                           muted autoplay controls></video>
                                </div>
                                <div class="video_text_wrap2">
                                    <div>MPS 사용 동영상</div>
                                    <div>사용자 중심의 UI로 BIG DATA 기반의 컨텐츠 제공</div>
                                    <div>일상적인 프린터사용에서 프린터 임대 및 유지보수에 이르기까지 한개의 프로그램으로 관리하세요.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- <section>
                 &lt;!&ndash;<common-site-map></common-site-map>&ndash;&gt;
             </section>-->
        </div>
        <common-footer></common-footer>
    </div>
</template>
<script>
    export default {
        name: 'Main',
        title: '틴텍 씨앤씨',
    }
</script>

<style scoped>

    /*pc header start*/

    #header {
        width: 100%;
        margin: 0 auto;
        height: 100px;

    }

    .m_header {
        display: none;
    }

    .header_container {
        position: absolute;
        height: 100px;
        width: 100%;
        left: 0;
        z-index: 1000;
    }

    .open {
        display: block;
        /*background-color: #F9F9F9;*/
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ start*/
    .header_container {
        position: relative;
        /*background: #f9f9f9;*/
        height: 300px;
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ end*/
    .header_container::before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
    }

    .header_container.open::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
        background-color: #fff;
    }


    .header_container .inner {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 100px;
        margin: 0 auto;
    }


    .header_container .logo {
        position: absolute;
        top: 28px;
        z-index: 111;
    }


    .header_menu_wrap {
        position: absolute;
        height: 100px;
        z-index: 30;
        width: 100%;
        left: 0px;
        top: 37px;
    }

    .header_menu_wrap > ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-left: 281px;
    }

    .header_menu_wrap > ul > li {
        width: 170px;
        display: inline-block;
        text-align: right;
        position: relative;
    }

    .header_menu_wrap > ul > li > a:hover {
        color: #0f70b7;
    }

    .sub_menu > ul > li:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .header_menu_wrap > ul > li > a {

        font-size: 1.8rem;
        letter-spacing: -0.54px;
        line-height: 27px;
        font-weight: 700;
        color: #000;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .header_menu_wrap > ul > li > a:hover {
        text-decoration: none;
    }


    .sub_menu {
        position: absolute;
        top: 63px;
        left: 50px;
        z-index: 30;
        padding-bottom: 36px;
        display: none;
    }

    .menu01 {
        left: 109px;
    }

    .menu02 {
        left: 71px;
    }

    .menu03 {
        left: 75px;
    }

    .menu04 {
        left: 86px;
    }

    .menu05 {
        left: 68px;
    }


    .sub_menu > ul {
        /* list-style: none;
         padding: 0;
         max-width: 1200px;
         margin: 0 auto;*/
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
    }

    .sub_menu > ul > li {
        /*width: calc(25% - 36px);*/
        width: 170px;

        padding-top: 26px;
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #333;
        text-align: left;
    }

    /*pc header end*/

    .main_background {
        width: 100%;
        height: 554px;
        background-color: #0f70b7;
        position: relative;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 113%;
    }

    .text_wrap {
        top: 144px;
        left: 715px;
        width: 40%;
        color: white;
        padding-top: 104px;
        padding-left: 20px;
    }

    .text_position {
        width: 1200px;
        margin: 0 auto;
    }

    .text_wrap > div:first-child {
        font-size: 2rem;
        padding-bottom: 40px;
    }

    .text_wrap > div:nth-child(2) {
        font-size: 6rem;
    }

    .test {
        position: absolute;
        top: 121px;
        left: 734px;
    }

    .text_wrap > div:nth-child(4) {
        font-size: 2.5rem;
        padding-bottom: 20px;
        padding-top: 67px;
        line-height: 1.5;
        width: 770px;
    }

    .text_wrap > div:nth-child(5) {
        font-size: 3rem;
    }

    .text_wrap button {
        background-color: transparent;
        border: 2px solid white;
        border-radius: 34px;
        font-size: 1.6rem;
        margin-top: 42px;
        padding: 10px 34px;
    }

    .text_wrap button a {
        color: white;
        text-decoration: none;
    }

    .text_solution_wrap {
        width: 1200px;
        margin: 0 auto;
        text-align: center;
        /*border: 2px solid black;*/
    }

    .text_solution_wrap div:first-child {
        font-size: 4.8rem;
        color: #222222;
        font-weight: bold;
        letter-spacing: -2px;
        padding: 110px 0 30px 0;
    }

    .text_solution_wrap div:nth-child(2) {
        font-size: 1.8rem;
        color: #202020;
    }

    .text_solution_wrap div:nth-child(3) {
        font-size: 1.8rem;
        color: #202020;
        padding-bottom: 58px;
    }

    .text_solution_wrap div:nth-child(4) {
        width: 801px;
        height: 339px;
        margin: 0 auto;
    }

    .text_solution_wrap div img {
        width: 100%;
        box-sizing: border-box;
    }

    .text_solution_wrap div:nth-child(5) {
        padding-top: 54px;
        font-size: 2rem;
    }

    .text_solution_wrap div:nth-child(5) > span {
        color: #0f70b7;
        font-weight: bold;
    }

    .text_wrap button:hover {
        box-shadow: 0px 0px 5px 0px rgba(255, 255, 225, 0.75);
        font-weight: bold;
    }

    .light_gray_background {
        width: 100%;
        height: 830px;
        background-color: #fafafa;
    }

    .three_content {
        width: 100%;
        height: 684px;
    }

    .detail_view_button {
        display: flex;
        justify-content: center;
    }

    .detail_view_button p,
    .detail_view_button img {
        cursor: pointer;
    }

    .detail_view_button p a {
        color: #333;
        font-weight: bold;
    }

    .detail_view_button p:hover {
        text-decoration: underline;
    }

    .detail_view_button img {
        padding-top: 5px;
        width: 7px
    }

    .stxt {
        font-size: 1.8rem;
        color: #000;
        line-height: 18px;
        letter-spacing: -0.9px;
        text-align: center;
        padding-top: 24px;
        line-height: 27px;
    }

    .service_wrap {
        width: 1200px;
        margin: 0 auto;
    }

    .service_wrap > div:first-child {
        font-size: 4.8rem;
        color: #222222;
        font-weight: bold;
        letter-spacing: -2px;
        text-align: center;
        padding: 110px 0 30px 0;
    }

    .service_wrap > div:nth-child(2),
    .service_wrap > div:nth-child(3) {
        text-align: center;
        font-size: 1.8rem;
        color: #202020;
    }

    .service_wrap ul {
        display: flex;
        justify-content: space-between;
    }

    .service_wrap ul li {
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 226px;
    }

    .service_wrap ul li img {
        padding-bottom: 27px;
        width: 100%;
    }

    .service_wrap ul li div:first-child {
        font-size: 2rem;
        font-weight: bold;
        color: #333;
        text-align: center;
        padding-bottom: 17px;
        line-height: 1.2;
    }

    .service_wrap ul li div:nth-child(2),
    .service_wrap ul li div:nth-child(3) {
        text-align: center;
        font-size: 1.6rem;
        color: #202020;
        line-height: 20px;
    }

    .service_wrap ul li div:nth-child(3) {
        padding-bottom: 100px;
    }

    .service_wrap li:nth-child(3) {
        text-align: center;
    }

    .four_content {
        width: 100%;
        height: 708px;
        background-color: #f7f9fb;
    }

    .video_wrap {
        width: 1200px;
        margin: 0 auto;
        text-align: center;
    }

    .video_wrap > div:first-child {
        font-size: 4.8rem;
        color: #222;
        font-weight: bold;
        letter-spacing: -2px;
        padding: 110px 0 30px 0;
    }

    .video_wrap > div:nth-child(2),
    .video_wrap > div:nth-child(3) {
        font-size: 1.8rem;
        color: #202020;
    }

    .video_wrap > div:nth-child(3) {
        padding-bottom: 105px;
    }

    .tnbox_video_wrap {
        display: flex;
        justify-content: space-between;
        margin-top: -32px;
    }

    .tnbox_video_wrap > div:first-child {
        position: relative;
    }

    .video_text_wrap {
        position: absolute;
        top: 18px;
        text-align: left;
        padding-left: 45px;
    }

    .video_text_wrap > div:first-child {
        font-size: 2.8rem;
        color: #ffffff;
        font-weight: bold;
        padding-bottom: 17px;
        padding-top: 40px;
    }

    .video_text_wrap > div:nth-child(2) {
        font-size: 1.6rem;
        color: #ffffff;
        font-weight: 500;
        padding-bottom: 23px;
        width: 175px;
        line-height: 22px;
    }

    .video_text_wrap > div:nth-child(3) {
        font-size: 1.3rem;
        color: #ffffff;
        width: 184px;
        line-height: 18px;
    }

    .video_text_wrap2 {
        position: absolute;
        top: 18px;
        text-align: left;
        padding-left: 45px;
    }

    .video_text_wrap2 > div:first-child {
        font-size: 2.8rem;
        color: #ffffff;
        font-weight: bold;
        padding-bottom: 17px;
        padding-top: 40px;
        left: 0;
        right: 0;
    }

    .video_text_wrap2 > div:nth-child(2) {
        font-size: 1.6rem;
        color: #ffffff;
        font-weight: 500;
        padding-bottom: 23px;
        width: 210px;
        line-height: 22px;
    }

    .video_text_wrap2 > div:nth-child(3) {
        font-size: 1.3rem;
        color: #ffffff;
        width: 195px;
        line-height: 18px;
    }

    .tnbox_video_wrap > div > img:nth-child(2) {
        width: 268px;
        height: 172px;
        position: absolute;
        top: 60px;
        left: 280px;
        cursor: pointer;
    }

    .tnbox_video_wrap > div:nth-child(2) {
        position: relative;
    }

    .tnbox_video_wrap > div:nth-child(2) > img:nth-child(2) {
        position: absolute;
        cursor: pointer;
    }

    video {
        position: absolute;
        top: 48px;
        left: 268px;
    }

    .m_mps_video,
    .m_tnbox_video {
        display: none;
    }

    .five_content {
        width: 100%;
        height: 202px;
        background-color: #ececec;
        border-bottom: 1px solid #cccccc;
    }

    .five_content > ul {
        padding: 40px 0 0 0;
        font-weight: bold;
        color: #666;
        font-size: 1.4rem;
        width: 1200px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: space-between;
    }

    .five_content > ul > li > ul {
        width: 240px;
        list-style: none;
        padding: 16px 0 0 0;
        font-weight: normal;
        color: #9a9a9a;
    }

    .five_content > ul > li > ul > li {
        padding-bottom: 12px;
        cursor: pointer;
    }

    .footer {
        width: 100%;
        height: 100px;
        background-color: #ececec;
    }

    .footer_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        font-size: 1.3rem;
        color: #9a9a9a;
        padding: 23px 20px 0px 0px;
    }

    .footer_wrap > div {
        margin-top: -13px;
    }

    .footer_flex {
        line-height: 18px;
        width: 100%;
        padding-left: 180px;
    }

    .footer_flex span {
        display: block;
        margin-top: 4px;
        font-size: 1.3rem;
        color: #9a9a9a;
    }

    .familyarea {
        margin-left: 72px;
    }

    .familyarea select {
        margin-left: 20px;
    }

    select {
        background: transparent;
        border: 1px solid #cccccc !important;
        width: 170px !important;
        height: 30px;
        color: #9a9a9a;
    }

    /*    .test {
            width: 1200px;
        }*/


    /*________________________________________________________________________________________tablet*/
    @media screen and (max-width: 1199px) and (min-width: 750px) {

        /*header start*/
        .tablet_header {
            display: block;
        }

        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 150px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */
        .m_header div:nth-child(2) {
            width: 30px;
            height: 30px;
        }

        .m_header div:nth-child(2) img {
            width: 100%;
        }

        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 1.6rem;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 50%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 45% !important;
            top: -7px;
        }

        .m_close_button > img {
            width: 24px;
            height: 24px;
        }

        .header_container {
            display: none;
            width: 100%;

        }

        .header_menu_wrap {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 340px;
            top: -7px;
        }

        .open {
            display: none;
        }

        /*header end*/
        /*fisrt section main_banner start*/
        .main {
            width: 100%;
        }

        .main_background {
            width: 100%;
            height: 418px;
            background-color: #0f70b7;
            position: relative;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 148%;
        }

        .text_wrap {
            width: 40%;
            color: white;
            padding-left: 130px;
            padding-top: 76px;
        }

        .text_wrap > div:nth-child(1) {
            width: 550px;
            padding-bottom: 22px;
        }

        .text_wrap > div:nth-child(2) {
            font-size: 4rem;
        }

        .text_wrap > div:nth-child(4) {
            line-height: 1.5;
            width: 95%;
            min-width: 500px;
            font-size: 2rem;
            padding-top: 40px;
        }

        .test {
            position: absolute;
            top: 91px;
            left: 494px;
        }

        /*fisrt section main_banner end*/
        /*second section start*/
        .light_gray_background {
            width: 100%;
            height: 660px;
        }

        .text_solution_wrap div:first-child {
            font-size: 3rem;
            padding: 90px 0 30px 0;
        }

        .text_solution_wrap > div:nth-child(4) {
            width: 557px;
            height: 300px;
            margin: 0 auto;
        }

        .text_solution_wrap > div:nth-child(5) {
            padding-top: 0;
        }

        .text_wrap button {
            background-color: transparent;
            border: 2px solid white;
            border-radius: 34px;
            font-size: 1.6rem;
            margin-top: 25px;
            padding: 7px 34px;
        }

        .text_solution_wrap {
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }

        /*second section end*/
        /*third section start*/
        .three_content {
            height: 915px;
        }

        .service_wrap ul {
            display: flex;
            flex-wrap: wrap;
            width: 550px;
            margin: 0 auto;
            list-style: none;
        }

        .service_wrap ul li {
            list-style: none;
            display: flex;
            flex-direction: column;
            width: 226px;
            align-items: center;
            margin-bottom: 43px;
        }

        .service_wrap ul li img {
            width: 254px;
            height: 174px;
            padding: 0;
        }

        .service_wrap ul li div:nth-child(3) {
            padding: 0;
        }

        .service_wrap {
            width: 100%;
            margin: 0 auto;
        }

        .service_wrap > div:first-child {
            font-size: 3rem;
            color: #222222;
            font-weight: bold;
            letter-spacing: -2px;
            text-align: center;
            padding: 83px 0 30px 0;
        }

        .service_wrap > div:nth-child(2) {
            width: 500px;
            margin: 0 auto;
            text-align: center;
            font-size: 1.8rem;
            color: #202020;
        }

        /*third section end*/
        /*fourth section start*/
        .m_tnbox_video,
        .m_mps_video {
            display: none;
        }

        .four_content {
            height: 1010px;
        }

        .tnbox_video_wrap {
            display: block;
            width: 800px;
            margin: 0 auto;
            padding: 0 20px;
        }

        .tnbox_video_wrap > div:nth-child(1) {
            padding-bottom: 125px;
        }

        .video_wrap {
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }

        .video_wrap > div:first-child {
            font-size: 3rem;
            color: #222;
            font-weight: bold;
            letter-spacing: -2px;
            padding: 83px 0 30px 0;
        }

        .video_wrap > div:nth-child(3) {
            padding-bottom: 50px;
        }

        .tnbox_video_wrap video {
            position: static;
        }

        .video_text_wrap {
            position: static;
            width: 300px;
            margin: -200px 240px 0 130px;
            padding-left: 0px;
        }

        .video_text_wrap2 {
            position: static;
            width: 300px;
            margin: -200px 240px 0 130px;
            padding-left: 0px;
        }

        .video_position {
            margin-top: -244px;
            margin-left: 200px;
        }

        .video_position2 {
            margin-top: -240px;
            margin-left: 200px;
        }

        /*fourth section end*/
        /*sitemap*/
        .five_content {
            width: 100%;
            padding: 0 40px;
        }

        .five_content > ul {
            width: 100%;
            margin: 0 auto;
            font-size: 1.3rem;
            letter-spacing: -0.5px;
        }

        .five_content > ul > li > ul {
            width: 100%;
        }

        /*footer*/
        .footer {
            width: 100%;
            height: 130px;
            background-color: #ececec;
            padding-bottom: 20px;
        }

        .footer .footer_wrap {
            width: 100%;
            padding: 0 40px;
        }

        .footer_wrap > div {
            margin-top: 14px;
        }

        .footer_flex {
            line-height: 18px;
            width: 100%;
            padding-left: 50px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }

        .footer_flex span {
            display: block;
            margin-top: 4px;
            font-size: 1.3rem;
            color: #9a9a9a;
        }

        .familyarea {
            margin-left: 0px;
            margin-top: 10px;
        }

        .familyarea select {
            margin-left: 0px;
        }


    }

    /*_____________________________________________________________________________________________mobile*/
    @media (max-width: 749px) {

        /*first section main banner start*/
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 105px;
            height: 25px;
        }

        .logo > a {
            display: inline-block;
        }

        .logo > a > img {
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */
        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 70%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
            overflow-y: auto;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 24%;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */
        .open {
            display: none;
        }

        .four_content {
            height: 681px;
        }

        .main {
            width: 100%;
        }

        .main_background {
            height: 275px;
        }

        .text_position {
            width: 100%;
        }

        .text_wrap {
            width: 100%;
            color: white;
            padding-left: 0;
            padding-top: 35px;
            margin: 0 auto;
        }

        .text_wrap > div:nth-child(1) {

            font-size: 1.2rem;
            padding-bottom: 0;
            text-align: center;
        }

        .text_wrap > div:nth-child(2) {
            text-align: center;
            font-size: 3.0rem;
            padding-bottom: 0;
            font-weight: 600;
            padding-top: 4px;
        }

        .test {
            position: absolute;
            top: 51px;
            left: 293px;
            width: 33px;
        }

        .test img {
            width: 100%;
        }

        .text_wrap > div:nth-child(4) {
            width: 100%;
            line-height: 24px;
            text-align: center;
            font-size: 1.4rem;
            padding: 24px 20px 0 20px;
            margin-bottom: 30px;

        }

        .text_wrap button {
            margin: 0 auto;
            text-align: center;
            font-size: 1.2rem;
            display: block;
            padding: 4px 30px;
        }


        .light_gray_background {
            height: 100%;
            padding-bottom: 40px;
        }

        .text_solution_wrap {
            width: 100%;
            padding: 0 20px;
        }

        .text_solution_wrap div:first-child {
            font-size: 2.4rem;
            line-height: 36px;
            letter-spacing: -1.56px;
            padding: 40px 0 20px 0;
        }

        .text_solution_wrap div:nth-child(2) {
            font-size: 1.6rem;
        }

        .text_solution_wrap div:nth-child(3) {
            font-size: 1.6rem;
            padding-bottom: 32px;
        }


        .text_solution_wrap div:nth-child(4) {
            width: 100%;
            padding: 0 20px;
            height: 100%;
        }

        .text_solution_wrap div:nth-child(5) {
            padding-top: 24px;
            font-size: 1.4rem;
        }

        .stxt {
            font-size: 1.4rem;
        }

        .three_content {
            height: 100%;
        }

        .service_wrap {
            width: 100%;
            padding: 0 20px;
        }

        .service_wrap > div:first-child {
            font-size: 2.4rem;
            line-height: 36px;
            letter-spacing: -1.56px;
            padding: 40px 0 20px 0;

        }


        .service_wrap > div:nth-child(2),
        .service_wrap > div:nth-child(3) {
            text-align: center;
            font-size: 1.6rem;
            color: #202020;
        }

        .service_wrap ul {
            display: block;
            width: 100%;
            text-align: center;
            padding: 6px 20px 40px 20px;
        }

        .service_wrap ul li {
            width: 152px;
            margin: 0 auto;
        }

        .service_wrap ul li div:first-child {
            line-height: 1.2;
            font-size: 1.6rem;
        }

        .service_wrap ul li div:nth-child(2),
        .service_wrap ul li div:nth-child(3) {
            text-align: center;
            font-size: 1.4rem;
            color: #202020;
            line-height: 16px;
        }

        .service_wrap ul li div:nth-child(3) {
            padding-bottom: 30px;
        }

        .service_wrap ul li img {
            padding-bottom: 0;
        }

        .video_position,
        .video_position2 {
            padding-top: 1px;
            position: absolute;
            top: 50px;
            left: 0;
            height: 75px;
            right: 0;
            display: flex;
            justify-content: center;
            z-index: 1;
        }

        .video_wrap {
            width: 100%;
        }

        .video_wrap > div:nth-child(2),
        .video_wrap > div:nth-child(3) {
            padding: 0 39px;
        }

        .video_wrap > div:first-child {
            font-size: 2.4rem;
            line-height: 36px;
            letter-spacing: -1.56px;
            padding: 40px 20px 20px 20px;
        }

        .video_wrap > div:first-child img {
            display: none;
        }

        .video_wrap > div:nth-child(2),
        .video_wrap > div:nth-child(3) {
            font-size: 1.6rem;
            color: #202020;
        }

        .video_wrap > div:nth-child(3) {
            padding-bottom: 58px;
        }

        .tnbox_video_wrap > div:first-child {
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;
            /*background:#29578a;*/
        }


        /*first section main banner end*/
        /*fourth section start*/
        .video_text_wrap > div:first-child,
        .video_text_wrap2 > div:first-child {
            font-size: 1.6rem;
            position: absolute;
            top: 0;
            padding: 0;
        }

        .video_text_wrap > div:nth-child(2),
        .video_text_wrap2 > div:nth-child(2) {
            font-size: 1.4rem;
            width: 100%;
            padding: 127px 15px 0 15px;
        }

        .video_text_wrap > div:nth-child(3),
        .video_text_wrap2 > div:nth-child(3) {
            font-size: 1.4rem;
            width: 100%;
            padding: 0 15px;
        }

        .video_text_wrap {
            text-align: center;
            text-align: center;
            padding: 0;
        }

        .video_text_wrap2 {
            display: block;
            /*position: static;*/
            padding: 0;
            text-align: center;
        }

        .video_text_wrap > div {
            width: 100%;
        }

        .pc_tnbox_video {
            display: none;
        }

        .pc_mps_video {
            display: none;
        }

        .tnbox_video_wrap {
            display: block;
        }

        .tn_box_video {
            height: 217px;
            width: 100%;
            background-color: #29578a;
        }

        .mps_video {
            width: 100%;
            height: 237px;
            background-color: #2c4764;
        }

        .m_tnbox_video,
        .m_mps_video {
            display: block;
        }

        .video_wrap {
            width: 100%;
            /*padding: 0 20px;*/
        }

        .tnbox_video_wrap img {
            display: none;
        }

        .video_text_wrap {
            display: block;
        }

        video {
            position: static;
            /*margin-top: 54px;*/
            /*margin-left: -20px;*/
        }

        /*fourth section end*/
        .five_content {
            display: none;
        }

        .footer {
            width: 100%;
            height: 197px;
            padding: 0 20px;
        }

        .footer_wrap {
            flex-direction: column;
            width: 100%;
            padding-top: 0;
        }

        .footer_wrap > div {
            text-align: center;
            margin: 0 auto;
        }

        .footer_wrap > div > img {
            width: 140px;
            height: 55px;
        }

        .footer_flex {
            padding-left: 0;
            flex-direction: column-reverse;
        }

        .foottxt {
            width: 100%;
            margin: 0 auto;
            padding-top: 25px;
        }

        .familyarea {
            margin-left: 0;
            padding-top: 10px;
        }

        .main_background {
            background-image: none !important;
        }

    }


    /*pc*/
    @media (max-width: 1200px) {
        .container {
            max-width: 1200px !important;
        }

        .header_container .open {
            display: block;
        }

        .tablet_header {
            display: none;
        }

    }

</style>

<script>
    import CommonHeader from "@/components/CommonHeader";
    import CommonFooter from "@/components/CommonFooter";

    export default {
        name: "Main",
        components: {
            CommonHeader,
            CommonFooter
        }
    }
</script>
