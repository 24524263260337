<template>
    <div style="height: 100vh; padding:0; margin: 0">
        <router-view/>
    </div>
</template>

<script>

    export default {
        name: 'App',

        components: {},

        data: () => ({
            //
        })
    };
</script>
