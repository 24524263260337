import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from "../views/Main";

// for router
Vue.use(VueRouter);

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About')
  },
    {
      path: '/main',
      name: 'Main',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "main" */ '@/views/Main')
    },
    {
      path: '/contactUs',
      name: 'ContactUs',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "contactUs" */ '@/views/ContactUs')
    },
    {
      path: '/partners',
      name: 'Partners',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners')
    },
    {
      path: '/service',
      name: 'Service',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "service" */ '@/views/Service')
    },
    {
      path: '/Support',
      name: 'Support',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "Support" */ '@/views/Support')
    },
    {
      path: '/recruit',
      name: 'Recruit',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "recruit" */ '@/views/Recruit')
    },
    {
      path: '/SupportTest',
      name: 'SupportTest',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "SupportTest" */ '@/views/SupportTest')
    }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
